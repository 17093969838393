import * as React from "react"
import { VideoCategory } from "../../data/videos"
import { VideoContainer } from "./VideoContainer"

// @ts-ignore
import styles from "./styles.module.scss"

export type Video = {
  description: string,
  videoId: string,
  title: string,
  category: VideoCategory;
  thumbnailUrl?: string;
  uploadDate?: string;
  duration?: string;
  embedUrl?: string;
  interactionCount?: number;
}

interface Props {
  videos: Video[];
}

{/*<CustomHelmet*/}
{/*title={"Videos. Mis videos desde Malta con toda la informaciôn que buscabas"}*/}
{/*description={"Costo de vida en Malta, visa de estudiante, trabajar en Malta, escuelas de inglés donde puedes estudiar. Encuentrálo todo en nuestros videos"}*/}
{/*type={"article"}*/}
{/*link={"https://desdemalta.com/videos/"}*/}
{/*/>*/}
{/*}*/}

const Videos = ({ videos }: Props) => (
  <>

    <div className={styles.videoPage}>
      {videos.map(video => {
        return (
          <div className={styles.video} key={video.videoId}>
            <VideoContainer title={video.title} id={video.videoId} description={video.description}/>
          </div>)
      })}
    </div>
  </>
)


export default Videos