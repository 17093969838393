import React, { useState } from "react"
import { graphql } from "gatsby"
import Tab from "@material-ui/core/Tab/Tab"
import Tabs from "@material-ui/core/Tabs/Tabs"
import PreviewItem, { ImageType } from "../components/PreviewItem"
import Videos from "../components/Videos";
import CustomHelmet from "../components/CustomHelmet";
import Layout from "../components/layout"

import { VideoCategory, videos } from "../data/videos"

const styles = require("./articulos.module.scss");

const categoryMap = {
  Info: "info",
  Lifestyle: "opinion",
  Videos: "Videos"
}

const categories = ["Info", "Videos"]

const filteredVideos = videos.filter(v => [VideoCategory.General, VideoCategory.Costos].some(c => c === v.category))

const Articulos = ({ data }) => {

  const [value, setValue] = useState(0);
  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <Layout>
      <CustomHelmet
        title={"Nuestros experiencias. Estudiar inglés en Malta experiencias para compartir"}
        description={"Estudiar inglés en Malta experiencias para compartir e informar acerca de las escuelas, la vida, el costo de vida y más información acerca de la isla"}
        link={"/articulos/"}
      />
      <Tabs className={styles.tabs} value={value} onChange={handleChange} aria-label="simple tabs example" indicatorColor={"primary"}>
        <Tab key={"Info"} label={"Posts"} />
        <Tab key={"Videos"} label={"Videos"} />
      </Tabs>
      <div className={styles.postsContainer}>
        {categories[value] !== categoryMap.Videos ? data.allWordpressPost.edges.sort( (a, b) => {
          // if a.node.acf.order is null, it will be sorted to the end, if b.node.acf.order is null, it will be sorted to the end as well if both have a order, sort by the lowest order if both have the same order show a before b
          console.log(a.node.acf)
          if (a.node.acf.order === null) {
            return 1;
          }
          if (b.node.acf.order === null) {
            return -1;
          }
          if (a.node.acf.order === b.node.acf.order) {
            return a.node.date > b.node.date ? 1 : -1;
          }
          return a.node.acf.order - b.node.acf.order;
        }).map(({node}) => {
          return (node.acf && node.acf.show) ? (
            <PreviewItem
              link={"/" + node.slug + "/"}
              imageUrl={node.featured_media ? node.featured_media.localFile.childImageSharp.fluid : ""}
              imageType={ImageType.squared}
              text={node.excerpt}
              title={node.title}
              key={node.slug}
              imageAlt={node.featured_media ? node.featured_media.alt_text : ""}
              categoryTag={node.acf.type}
            />
          ) : <React.Fragment key={node.slug}/>
        }) : < Videos videos={filteredVideos}/>}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: [date] }) {
      edges {
        node {
          title
          excerpt
          slug
          categories  
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 300, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          acf {
            type
            show
            order
          }
        }
      }
    }
  }`


export default Articulos;
